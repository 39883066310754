export interface IJwtPayload {
  country: string;
  currency: string;
  customerType: string;
  exp: number;
  iat: number;
  locale: string;
  merchantCustomerId: string;
  scale: number;
  sub: string;
  virtualMode: boolean;
}

function base64UrlDecode(base64Url: string): string {
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  while (base64.length % 4) {
    base64 += '=';
  }
  return atob(base64);
}

/**
 * Decodes a JSON Web Token (JWT) and returns its payload.
 *
 * @param jwtToken - The JWT string to decode.
 * @returns The decoded JWT payload as a `IJwtPayload` object, or `null` if the token is invalid or expired.
 *
 * @remarks
 * The function expects the JWT to have three parts separated by dots (header, payload, and signature).
 * If the token is not properly formatted or if the payload cannot be decoded, the function returns `null`.
 * Additionally, the function checks if the token has expired based on the `exp` claim in the payload.
 *
 * @example
 * ```typescript
 * const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';
 * const payload = decodeJwt(token);
 * if (payload) {
 *   console.log('Decoded payload:', payload);
 * } else {
 *   console.log('Invalid or expired token');
 * }
 * ```
 */
export function decodeJwt(jwtToken: string): IJwtPayload | null {
  const parts = jwtToken.split('.');
  if (parts.length !== 3) {
    return null;
  }

  const payload = parts[1];
  try {
    const decoded = JSON.parse(base64UrlDecode(payload)) as IJwtPayload;

    // Check expiration
    if (decoded.exp * 1000 < Date.now()) {
      console.error('Token has expired.');
      return null;
    }

    return decoded;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
}
