import { lazy, type ReactElement, StrictMode, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import { Suspendable } from '@core/components/Suspendable';
import { ENV } from '@core/constants';
import { setupStore } from '@core/store';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import '@source/index.css';
const store = setupStore();

const cashierVersion =
  new URL(location.href).searchParams.get('version') ?? 'v1';
const direction = new URL(location.href).searchParams.get('dir');

const isSentryEnabled =
  window.globalENV.VITE_ENVIRONMENT === 'prod' ||
  window.globalENV.VITE_ENVIRONMENT === 'test';

const siteId = ENV.VITE_HOTJAR_SITE_ID;
const hotjarVersion = ENV.VITE_HOTJAR_VERSION;

const CashierV1 = Suspendable(lazy(() => import('./v1')));
const CashierV2 = Suspendable(lazy(() => import('./v2')));

if (cashierVersion === 'v2' && direction) {
  document.documentElement.setAttribute('dir', direction);
}

if (ENV.VITE_ENVIRONMENT === 'prod') {
  Hotjar.init(siteId, hotjarVersion, {
    nonce: 'rAnDoM',
  });
}

if (isSentryEnabled) {
  Sentry.init({
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Performance Monitoring
    enabled: isSentryEnabled,
    // Set tracesSampleRate to 1.0 to capture 100%
    dsn: ENV.VITE_SENTRY_DSN,
    environment: ENV.VITE_SENTRY_ENVIRONMENT,
    release: ENV.VITE_CASHIER_RELEASE_VERSION,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ENV.VITE_CASHIER_API_URL],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /webkit-masked-url:\//i,
    ],
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        matchRoutes,
        useNavigationType,
        createRoutesFromChildren,
      }),
    ],
    ignoreErrors: [
      // Hotjar errors
      "null is not an object (evaluating 'window.localStorage.getItem')",
      "null is not an object (evaluating 'window.localStorage.removeItem')",
      'Member PerformanceObserverInit.entryTypes is required and must be an instance of sequence',
      // '$ is not defined' - maybe related to praxis,
      // Browser extensions errors
      'Cannot redefine property: googletag',
      "Cannot read properties of undefined (reading 'site_id')",
      "null is not an object (evaluating 'iframe.contentWindow.postMessage')",
      // others
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Object captured as promise rejection with keys: error, isUnhandledError, meta',
      'Object captured as promise rejection with keys:',
      /Object captured as promise rejection with keys:/i,
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

const cashierModule: Record<string, ReactElement> = {
  v1: <CashierV1 />,
  v2: <CashierV2 />,
};

const domNode = document.getElementById('root') as HTMLElement;

createRoot(domNode).render(
  <StrictMode>
    <ReduxProvider store={store}>
      {cashierModule[cashierVersion] ?? cashierModule.v1}
    </ReduxProvider>
  </StrictMode>
);
