import { API } from '@core/constants/apiRouting';
import { ENV } from '@core/constants/env';
import { INTENT_TYPE } from '@core/constants/intent';
import { type METHOD_NAME, METHODS } from '@core/constants/methods';
import { type PROVIDER_NAME, PROVIDERS } from '@core/constants/providers';
import { ROUTES } from '@core/constants/routes';
import { STATUS } from '@core/constants/status';

const applePayMinVersionSupport = 16;

const NODA_PAY_COUNTRY_IBAN_VALIDATION_METHODS = [
  METHODS.OPEN_BANKING,
  METHODS.COMMERZBANK,
  METHODS.HYPOVEREINS,
  METHODS.NORISBANK,
  METHODS.POSTBANK,
  METHODS.VOLKSBANKEN_RAIFFEISEN,
  METHODS.SPARKASSE,
  METHODS.DEUTSCHE_BANK,
];

export const fieldsToExcludeNonLatinValidation = [
  'BIRTHDAY',
  'EMAIL',
  'IBAN',
  'PHONE',
  'CPF',
  'ACCOUNT_NUMBER',
  'ROUTING_CODE',
  'BSB_CODE',
  'PIX_KEY',
  'VOUCHER_PIN',
];

export {
  ENV,
  API,
  STATUS,
  ROUTES,
  METHODS,
  PROVIDERS,
  INTENT_TYPE,
  type METHOD_NAME,
  type PROVIDER_NAME,
  applePayMinVersionSupport,
  NODA_PAY_COUNTRY_IBAN_VALIDATION_METHODS,
};
